<template>
  <UiPopup
    :model-value="modelValue"
    title="Team can't be deleted"
    description="To delete a team, first all agents must be removed from the team. You can remove agents in the team edit mode."
    primary-button-text="Edit team"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="$emit('input', team), emits('update:modelValue', false)"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import type { Team } from '@/types'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  team: Team
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})
</script>

<style scoped></style>
